var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PW_LENGTH_MIN, PW_LENGTH_MAX, REGEX_PHONE } from 'renderer/constants';
import { GENDER_ENUM } from 'renderer/models';
import { RestClient, PhoneNumberUtils, isNil } from 'renderer/utils';
export var BizNumberValidator = function (businessLicenseNumber, store) { return __awaiter(void 0, void 0, void 0, function () {
    var isValid, numberMap, keyArr, chk, error_1;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!businessLicenseNumber) {
                    if (store && !!store.smsService) {
                        if (((_b = (_a = store === null || store === void 0 ? void 0 : store.smsService) === null || _a === void 0 ? void 0 : _a.kakao) === null || _b === void 0 ? void 0 : _b.provider) === 'popbill') {
                            return [2 /*return*/, Promise.reject('문자서비스 가입중에는 사업자등록번호를 변경할 수 없습니다. 하단의 고객센터로 연락주세요.')];
                        }
                        else {
                            return [2 /*return*/, Promise.resolve()];
                        }
                    }
                    return [2 /*return*/, Promise.resolve()];
                }
                isValid = false;
                numberMap = businessLicenseNumber
                    .replace(/-/gi, '')
                    .split('')
                    .map(function (d) {
                    return parseInt(d, 10);
                });
                if (numberMap.length == 10) {
                    keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
                    chk = 0;
                    keyArr.forEach(function (d, i) {
                        chk += d * numberMap[i];
                    });
                    chk += parseInt(((keyArr[8] * numberMap[8]) / 10), 10);
                    isValid = Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
                }
                if (!isValid) {
                    return [2 /*return*/, Promise.reject('올바른 사업자등록 번호가 아닙니다.')];
                }
                if (store && store.smsService) {
                    if (businessLicenseNumber === store.businessLicenseNumber) {
                        return [2 /*return*/, Promise.resolve()];
                    }
                    else {
                        if (((_d = (_c = store === null || store === void 0 ? void 0 : store.smsService) === null || _c === void 0 ? void 0 : _c.kakao) === null || _d === void 0 ? void 0 : _d.provider) === 'popbill') {
                            return [2 /*return*/, Promise.reject('문자서비스 가입중에는 사업자등록번호를 변경할 수 없습니다. 하단의 고객센터로 연락주세요.')];
                        }
                        else {
                            return [2 /*return*/, Promise.resolve()];
                        }
                    }
                }
                _e.label = 1;
            case 1:
                _e.trys.push([1, 3, , 4]);
                return [4 /*yield*/, RestClient.getInstance().post('/external/validate', {
                        type: 'businessLicenseNumber',
                        data: { storeId: store === null || store === void 0 ? void 0 : store.id, businessLicenseNumber: businessLicenseNumber }
                    })];
            case 2:
                _e.sent();
                return [2 /*return*/, Promise.resolve()];
            case 3:
                error_1 = _e.sent();
                return [2 /*return*/, Promise.reject(error_1.message)];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var PhoneNumberValidator = function (phone, storeId, customerId, countryCode) { return __awaiter(void 0, void 0, void 0, function () {
    var error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!phone) {
                    return [2 /*return*/, Promise.resolve()];
                }
                if (!PhoneNumberUtils.isValidPhoneNumber(phone, (countryCode || 'kr').toUpperCase())) {
                    return [2 /*return*/, Promise.reject('올바른 휴대폰번호를 입력해주세요.')];
                }
                if (countryCode === 'kr' || !countryCode) {
                    if (!phone.match(REGEX_PHONE)) {
                        return [2 /*return*/, Promise.reject('올바른 휴대폰번호를 입력해주세요.')];
                    }
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, RestClient.getInstance().post('/external/validate', {
                        type: 'customerPhone',
                        data: { storeId: storeId, phone: phone, customerId: customerId }
                    })];
            case 2:
                _a.sent();
                return [2 /*return*/, Promise.resolve()];
            case 3:
                error_2 = _a.sent();
                return [2 /*return*/, Promise.reject(error_2.message)];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var CustomerNumberValidator = function (customerNumber, storeId, lastCustonerNumber, customerId) { return __awaiter(void 0, void 0, void 0, function () {
    var error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!!customerNumber && /^\d+$/g.test(customerNumber) === false) {
                    return [2 /*return*/, Promise.reject('숫자만 입력가능합니다.')];
                }
                if (!!customerNumber && /^[1-9]\d*$/g.test(customerNumber) === false) {
                    return [2 /*return*/, Promise.reject('0으로 시작하지 않는 숫자만 입력가능합니다.')];
                }
                if (isNil(customerNumber) || customerNumber === '') {
                    return [2 /*return*/, Promise.resolve()];
                }
                if (customerNumber > 99999999) {
                    return [2 /*return*/, Promise.reject('고객번호는 0이상 99999999이하의 숫자만 입력 가능합니다.')];
                }
                if (lastCustonerNumber + 1 === customerNumber) {
                    return [2 /*return*/, Promise.resolve()];
                }
                if (customerNumber <= 0) {
                    return [2 /*return*/, Promise.reject('고객번호는 0이상 99999999이하의 숫자만 입력 가능합니다.')];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, RestClient.getInstance().post("/stores/".concat(storeId, "/customers/numbers/check"), {
                        storeId: storeId,
                        customerNumber: customerNumber,
                        customerId: customerId
                    })];
            case 2:
                _a.sent();
                return [2 /*return*/, Promise.resolve()];
            case 3:
                error_3 = _a.sent();
                return [2 /*return*/, Promise.reject(error_3.message)];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var FIELDS = {
    password: {
        type: 'password',
        itemProps: {
            name: 'password',
            label: '비밀번호*',
            rules: [{ type: 'string', min: PW_LENGTH_MIN, max: PW_LENGTH_MAX, required: true }]
        },
        inputProps: {
            placeholder: '비밀번호'
        }
    },
    passwordConfirm: {
        type: 'password',
        itemProps: {
            name: 'passwordConfirm',
            label: '비밀번호 확인*'
        },
        inputProps: {
            placeholder: '비밀번호'
        }
    },
    name: {
        type: 'text',
        itemProps: {
            name: 'name',
            label: '이름*',
            rules: [{ type: 'string', required: true, min: 2, max: 10 }]
        },
        inputProps: { placeholder: '이름을 입력해주세요.' }
    },
    tel: {
        type: 'text',
        itemProps: {
            name: 'tel',
            label: '유선번호',
            rules: [{ type: 'string', max: 13 }]
        },
        inputProps: {
            placeholder: '예시) 02-000-0000'
        }
    },
    gender: {
        type: 'select',
        itemProps: {
            name: 'gender',
            label: '성별',
            rules: [{ type: 'string' }]
        },
        inputProps: {
            placeholder: '성별 선택',
            options: [GENDER_ENUM.FEMALE, GENDER_ENUM.MALE].map(function (item) {
                return {
                    value: item,
                    label: item === GENDER_ENUM.FEMALE ? '여성' : '남성'
                };
            })
        }
    },
    email: {
        type: 'text',
        itemProps: {
            name: 'email',
            label: '이메일',
            rules: [{ type: 'string' }]
        },
        inputProps: {
            placeholder: '예시) test@test.com'
        }
    },
    ceoName: {
        type: 'text',
        itemProps: {
            name: 'ceoName',
            label: '대표자*',
            rules: [{ type: 'string', required: true, min: 2, max: 10 }]
        },
        inputProps: {
            placeholder: '예시 ) 홍길동'
        }
    },
    birth: {
        type: 'datepicker',
        itemProps: {
            name: 'birth',
            label: '생일',
            rules: [{ type: 'date' }]
        },
        inputProps: {
            placeholder: '생일을 입력해주세요.'
        }
    },
    memo: {
        type: 'textarea',
        itemProps: {
            name: 'memo',
            label: '메모',
            rules: [{ type: 'string', min: 0, max: 500 }]
        },
        inputProps: { placeholder: '메모를 입력' }
    }
};
