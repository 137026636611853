var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import Row from 'antd/lib/row';
import { AntdForm, BigTitleModal, BodyTemplate, Icon, TextComponent, ButtonWithPlusCircle, ResetButton, ResponsiveWithPlusIcon, CustomDropdown, FormItemWithoutInput, Marginer, EntireLoader, ScrollBarWithMeasure, MyEditor, removeMDMarker } from 'renderer/components';
import { DEFAULT_VARIABLES } from 'renderer/models';
import { MessageTemplateQuery, StoreQuery } from 'renderer/queries';
import { useWindowSize, isEmpty, getByteLength, Lodash, createUUID, uploadFileToMessageImageDirectory, deleteFileToMessageImageDirectory, getBase64FromImageFile, isPlussharpMobileAppAndAndroid, checkEucKrCompatibility, removeZeroWidthSpace } from 'renderer/utils';
import styled from 'styled-components';
import { Col } from 'antd/lib/grid';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Form from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { COLORS } from 'renderer/styles';
import { IMAGES } from 'renderer/assets';
import Upload from 'antd/lib/upload';
import Button from 'antd/lib/button';
import { FORM_VALIDATION_ERROR_MESSAGE, THINKOFYOU_080_INBOUND_NUMBER } from 'renderer/constants';
var MessageTemplates = function (props) {
    var store = StoreQuery.getMyStoreInCache();
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var _a = useWindowSize(), isPC = _a.isPC, height = _a.height;
    var templateList = MessageTemplateQuery.useMessageTemplates(storeId, { enabled: !!storeId });
    var getList = function (isStoreTemplate) {
        var _a;
        return (((_a = templateList.data) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return (isStoreTemplate ? item.storeId === storeId : !item.storeId); }).filter(Boolean)) || []);
    };
    var deleteMutation = MessageTemplateQuery.useDeleteMessageTemplateMutation();
    var handleUpdate = function (id) {
        var _a;
        var target = (_a = templateList.data) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.id === id; });
        setTemplate(target || { title: '', content: { body: '' }, isAd: false });
    };
    var handleDelete = function (id) {
        Modal.confirm({
            cancelText: '아니오',
            okText: '예',
            title: '정말 삭제하시겠어요?',
            onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, deleteMutation.mutateAsync({ storeId: storeId, id: id })];
                        case 1:
                            _a.sent();
                            notification.success({ message: '문자 템플릿 삭제가 완료되었습니다.' });
                            return [2 /*return*/];
                    }
                });
            }); }
        });
    };
    var _b = React.useState(), template = _b[0], setTemplate = _b[1];
    return store ? (React.createElement(BodyTemplate, { title: "\uB0B4 \uBB38\uC790 \uBCF4\uAD00\uD568", extra: React.createElement(ResponsiveWithPlusIcon, { children: "\uC0C8 \uBB38\uC790 \uCD94\uAC00", onClick: function () { return handleUpdate(); }, style: { marginTop: '5px' } }), content: React.createElement(React.Fragment, null,
            !isPC && React.createElement(Marginer, { height: 45 }),
            React.createElement(ScrollBarWithMeasure, null,
                React.createElement(Wrapper, null,
                    React.createElement(Row, { gutter: 16 }, getList(true).map(function (template) {
                        return (React.createElement(Col, { key: template.id, md: 24, lg: 8, style: { width: '100%' } },
                            React.createElement(MessageCard, { template: template, handleDelete: handleDelete, handleUpdate: handleUpdate })));
                    })),
                    React.createElement(MessageTemplateEditor, { template: template, onCancel: function () { return setTemplate(undefined); }, store: store })))) })) : (React.createElement(EntireLoader, { open: true }));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 14px;\n"], ["\n  padding: 14px;\n"])));
var MessageTemplateEditor = function (props) {
    var _a;
    var isUpdated = (_a = props.template) === null || _a === void 0 ? void 0 : _a.id;
    // submitButton?: string;
    // onSubmit: (data: any) => void;
    // formProps: FormProps<any>;
    // fields: (Field | InlineGroupField)[];
    // allowEnterSubmit?: boolean;
    var form = useForm()[0];
    var createMutation = MessageTemplateQuery.useCreateMessageTemplateMutation();
    var updateMutation = MessageTemplateQuery.useUpdateMessageTemplateMutation();
    var handleSubmit = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var imageObject, uploadResult, image, error_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 7, , 8]);
                    imageObject = Lodash.get(value, 'content.image.0');
                    uploadResult = void 0;
                    if (!(imageObject && typeof imageObject !== 'string')) return [3 /*break*/, 2];
                    return [4 /*yield*/, uploadFileToMessageImageDirectory(imageObject.originFileObj, createUUID())];
                case 1:
                    uploadResult = _e.sent();
                    _e.label = 2;
                case 2:
                    image = (uploadResult === null || uploadResult === void 0 ? void 0 : uploadResult.location) ? uploadResult.location : (_a = value.content) === null || _a === void 0 ? void 0 : _a.image;
                    if (!isUpdated) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateMutation.mutateAsync(__assign(__assign(__assign(__assign({}, props.template), { storeId: (_b = props.store) === null || _b === void 0 ? void 0 : _b.id }), value), { content: __assign(__assign({}, value.content), { image: image, body: removeZeroWidthSpace((_c = value.content) === null || _c === void 0 ? void 0 : _c.body) }) }))];
                case 3:
                    _e.sent();
                    return [3 /*break*/, 6];
                case 4: return [4 /*yield*/, createMutation.mutateAsync(__assign(__assign({ storeId: (_d = props.store) === null || _d === void 0 ? void 0 : _d.id }, value), { content: __assign(__assign({}, value.content), { image: image }) }))];
                case 5:
                    _e.sent();
                    _e.label = 6;
                case 6:
                    notification.success({ message: "\uBB38\uC790 \uD15C\uD50C\uB9BF ".concat(isUpdated ? '수정' : '생성', "\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.") });
                    props.onCancel();
                    return [3 /*break*/, 8];
                case 7:
                    error_1 = _e.sent();
                    notification.error({ message: error_1.message });
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        form.setFieldsValue(__assign(__assign({}, props.template), { isAd: typeof ((_a = props.template) === null || _a === void 0 ? void 0 : _a.isAd) === 'boolean' ? (_b = props.template) === null || _b === void 0 ? void 0 : _b.isAd : true, title: ((_c = props.template) === null || _c === void 0 ? void 0 : _c.title) || '', content: {
                body: ((_e = (_d = props.template) === null || _d === void 0 ? void 0 : _d.content) === null || _e === void 0 ? void 0 : _e.body) || '',
                image: ((_g = (_f = props.template) === null || _f === void 0 ? void 0 : _f.content) === null || _g === void 0 ? void 0 : _g.image) || ''
            } }));
    }, [props.template]);
    return (React.createElement(BigTitleModal, { centered: true, maskClosable: false, visible: !!props.template, onCancel: props.onCancel, cancelText: "\uB2EB\uAE30", okText: "\uC800\uC7A5", onOk: form.submit, title: isUpdated ? '문자 템플릿 수정' : '새 문자 템플릿 등록', 
        // width={props.isPC ? 800 : props.width}
        children: props.template && (React.createElement("div", { style: { padding: '10px' } },
            React.createElement(AntdForm, { onSubmit: handleSubmit, formProps: { form: form, layout: 'vertical', initialValues: props.template, onFinish: handleSubmit }, fields: [
                    {
                        type: 'text',
                        itemProps: {
                            wrapperCol: {
                                colSpan: 24
                            },
                            name: 'title',
                            label: '제목',
                            rules: [{ required: true, type: 'string' }]
                        },
                        inputProps: { placeholder: '제목입력' }
                    },
                    {
                        element: (React.createElement("div", { style: { marginRight: '10px' } },
                            React.createElement(MessageContentEditor, { form: form, template: props.template, availables: {
                                    templateSaver: false,
                                    templateSelector: true
                                }, variables: DEFAULT_VARIABLES })))
                    }
                ] }))) }));
};
// const Ad
export var AD_PREFIX = function (storeName) { return "(\uAD11\uACE0)".concat(storeName); };
export var AD_SUFFIX = function (storeId) { return "\n\uBB34\uB8CC\uAC70\uBD80:".concat(THINKOFYOU_080_INBOUND_NUMBER); };
// `\n무료거부:${THINKOFYOU_080_INBOUND_NUMBER}-인증번호:${convert080AuthFromStoreId(storeId)}`;
var convert080AuthFromStoreId = function (storeId) {
    return "".concat(1000000 - storeId).padStart(6, '0');
};
export var MessageContentEditor = function (props) {
    var _a, _b;
    var ref = React.useRef();
    var hasVariables = !isEmpty(props.variables);
    React.useEffect(function () {
        if (props.template) {
            handleTemplateSelect(props.template);
        }
    }, [props.template]);
    React.useEffect(function () {
        // 템플릿이 없는데
        if (props.visible && !props.template) {
            handleReset();
        }
    }, [props.visible]);
    var _c = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _c[1];
    var store = StoreQuery.getMyStoreInCache();
    var body = props.form.getFieldValue(['content', 'body']);
    var picture = props.form.getFieldValue(['content', 'image']);
    var hasImage = !!picture;
    var _d = React.useState(body), initialValue = _d[0], setInitialValue = _d[1];
    var handleChangeContent = function () {
        props.form
            .validateFields(['content', 'body'])
            .then(function (value) {
            var _a;
            try {
                contentValidator(undefined, ((_a = value === null || value === void 0 ? void 0 : value.content) === null || _a === void 0 ? void 0 : _a.body) || '');
            }
            catch (e) { }
        })
            .catch(function (error) { });
        forceUpdate();
        if (props.onChangeContent) {
            props.onChangeContent();
        }
    };
    var addAdBlocks = function () {
        props.form.setFieldsValue({ isAd: !isAd });
        handleChangeContent();
    };
    var insertBlocksFromHtml = function (text) {
        ref.current.getInstance().insertText(text);
        props.form.setFieldsValue({ content: { body: removeMDMarker(ref.current.getInstance().getMarkdown()) } });
        handleChangeContent();
    };
    var handleReset = function () {
        handleTemplateSelect(props.template);
    };
    var handleTemplateSelect = function (template) {
        var _a, _b, _c, _d;
        props.form.setFieldsValue({
            isAd: typeof (template === null || template === void 0 ? void 0 : template.isAd) === 'boolean' ? template === null || template === void 0 ? void 0 : template.isAd : true,
            content: {
                image: (_a = template === null || template === void 0 ? void 0 : template.content) === null || _a === void 0 ? void 0 : _a.image,
                body: (_b = template === null || template === void 0 ? void 0 : template.content) === null || _b === void 0 ? void 0 : _b.body
            },
            title: (template === null || template === void 0 ? void 0 : template.title) || ''
        });
        setInitialValue((_c = template === null || template === void 0 ? void 0 : template.content) === null || _c === void 0 ? void 0 : _c.body);
        ref.current.getInstance().setMarkdown((_d = template === null || template === void 0 ? void 0 : template.content) === null || _d === void 0 ? void 0 : _d.body);
        handleChangeContent();
    };
    var handlePictureChange = function (file) {
        props.form.setFieldsValue({ content: { image: file } });
        handleChangeContent();
    };
    var _e = React.useState(''), error = _e[0], setError = _e[1];
    var contentValidator = function (_, value) {
        if (!value) {
            setError('본문을 입력해주세요.');
            return Promise.reject(new Error(FORM_VALIDATION_ERROR_MESSAGE));
        }
        else {
            var image = props.form.getFieldValue(['content', 'image']);
            var type = props.form.getFieldValue(['type']);
            var isAd_1 = props.form.getFieldValue(['isAd']);
            value = value + (isAd_1 ? getAdText(store) : '');
            var error_2;
            if (type === 'message' || !type) {
                if (getByteLength(value) > 2000) {
                    error_2 = '문자는 2000kb를 넘을 수 없습니다.';
                }
            }
            else {
                var bodyLength = value.length;
                if (image) {
                    if (bodyLength > 400) {
                        error_2 = '이미지 친구톡은 400자를 넘을 수 없습니다.';
                    }
                }
                else {
                    if (bodyLength > 1000) {
                        error_2 = '친구톡은 1000자를 넘을 수 없습니다.';
                    }
                }
            }
            var compatibility = checkEucKrCompatibility(value);
            if (!compatibility.compatible) {
                error_2 = "\uC774\uBAA8\uC9C0(ex. \uD83D\uDE01) \uB4F1 \uBB38\uC790\uC5D0\uC11C \uD5C8\uC6A9\uD558\uC9C0 \uC54A\uB294 \uBB38\uC790\uAC00 \uC788\uC2B5\uB2C8\uB2E4. (".concat(compatibility.incompatibleChars.join(', '), ")");
            }
            if (!error_2) {
                setError(undefined);
                return Promise.resolve();
            }
            else {
                setError(error_2);
                return Promise.reject(new Error(FORM_VALIDATION_ERROR_MESSAGE));
            }
        }
    };
    var isAd = props.form.getFieldValue(['isAd']);
    return (React.createElement(React.Fragment, null,
        React.createElement(MessageTemplateContentWrapper, { error: !!error },
            React.createElement(Row, { justify: "space-between", className: "header", align: "middle" },
                React.createElement(ByteChecker, { body: body, isAd: isAd, store: store, hasImage: hasImage, type: props.form.getFieldValue(['type']) }),
                React.createElement(ResetButton, { onClick: handleReset, icon: React.createElement(Icon, { name: "rotate", color: COLORS.calendarRedActive }) }, "\uB2E4\uC2DC\uC4F0\uAE30")),
            React.createElement("div", { style: {
                    maxHeight: '270px',
                    overflowY: 'hidden',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px'
                } },
                picture && React.createElement(MessageImage, { image: picture, onClick: function () { return handlePictureChange(undefined); } }),
                isAd && React.createElement(AdPrefixer, { children: AD_PREFIX((store === null || store === void 0 ? void 0 : store.name) || '') }),
                React.createElement(MyEditor, { ref: ref, height: isAd && hasImage ? '100px' : hasImage ? '160px' : isAd ? '180px' : '230px', initialValue: initialValue, onChange: function (content) {
                        props.form.setFieldsValue({ content: { body: content } });
                        handleChangeContent();
                    }, placeholder: "\uBCF4\uB0B4\uC2E4 \uB0B4\uC6A9\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694. 90\uBC14\uC774\uD2B8 \uCD08\uACFC\uC2DC LMS\uB85C \uC790\uB3D9\uC804\uD658, \uC0AC\uC9C4\uCD94\uAC00 \uC2DC MMS\uB85C \uC790\uB3D9\uC804\uD658\uB429\uB2C8\uB2E4." }),
                isAd && React.createElement(AdPrefixer, { children: AD_SUFFIX(store === null || store === void 0 ? void 0 : store.id) })),
            React.createElement(Form.Item, { name: ['title'], style: { display: 'none' } }),
            React.createElement(Form.Item, { name: ['content', 'image'], style: { display: 'none' } }),
            React.createElement(Form.Item, { name: ['content', 'body'], style: { display: 'none' } }),
            React.createElement(Form.Item, { name: ['isAd'], style: { display: 'none' } }),
            React.createElement("div", { className: "footer" },
                React.createElement(UnderlineClickableText, { onClick: function () { return addAdBlocks(); } },
                    "(\uAD11\uACE0)+080\uC218\uC2E0\uAC70\uBD80 ",
                    isAd ? '제거' : '삽입'),
                React.createElement(Row, { style: { marginTop: '10px' } },
                    React.createElement(PictureSelector, { file: picture, onChange: function (data) {
                            handlePictureChange(isEmpty(data) ? undefined : data);
                        } }),
                    hasVariables && React.createElement(VariablesSelector, { variables: props.variables, onClick: insertBlocksFromHtml }),
                    ((_a = props.availables) === null || _a === void 0 ? void 0 : _a.templateSelector) && React.createElement(TemplateSelector, { store: store, onSelect: handleTemplateSelect }),
                    ((_b = props.availables) === null || _b === void 0 ? void 0 : _b.templateSaver) && (React.createElement(TemplateSaver, { store: store, values: __assign(__assign({}, props.template), props.form.getFieldsValue()), onClick: insertBlocksFromHtml }))))),
        error && React.createElement(MessageError, { error: error }),
        React.createElement(FormItemWithoutInput, { name: ['content', 'body'], rules: [
                {
                    validator: contentValidator,
                    message: ''
                }
            ] })));
};
export var PictureSelector = function (props) {
    var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
    var _b = React.useState([]), fileList = _b[0], setFileList = _b[1];
    var _c = React.useState(false), changed = _c[0], setChanged = _c[1];
    var _d = React.useState(''), error = _d[0], setError = _d[1];
    var handleClose = function () {
        setVisible(false);
        setError(undefined);
    };
    React.useEffect(function () {
        var _a;
        var file = props.file;
        if (!file) {
            setFileList([]);
        }
        if (typeof file === 'string') {
            var name = (_a = file === null || file === void 0 ? void 0 : file.split('/')) === null || _a === void 0 ? void 0 : _a.pop();
            setFileList([{ name: name, url: file }]);
        }
        else {
            setFileList(file ? file : []);
        }
        setChanged(false);
    }, [props.file]);
    return (React.createElement("div", null,
        React.createElement(TextButtonWithIcon, { label: "\uC0AC\uC9C4\uCD94\uAC00", icon: React.createElement(Icon, { name: "paperclip" }), onClick: function () { return setVisible(true); } }),
        React.createElement(BigTitleModal, { centered: true, open: visible, cancelText: "\uB2EB\uAE30", okText: "\uC800\uC7A5", onCancel: function () { return handleClose(); }, okButtonProps: {
                disabled: !!error || ((isEmpty(fileList) || (fileList === null || fileList === void 0 ? void 0 : fileList.length) === 0) && !changed)
            }, onOk: function () {
                props.onChange(fileList);
                handleClose();
            }, title: "\uBB38\uC790 \uC0AC\uC9C4\uCD94\uAC00", children: React.createElement("div", { style: { padding: '10px' } },
                React.createElement(TextComponent, { children: "\uC774\uBBF8\uC9C0 \uD615\uC2DD : .jpg .jpeg" }),
                React.createElement(TextComponent, { children: "\uC774\uBBF8\uC9C0 \uD06C\uAE30 : \uAC00\uB85C 500px~1000px / \uC138\uB85C 250px~1300px(\uAC00\uB85C \uAE30\uC9000.5~1.3\uBC30\uC728 \uC774\uD558)" }),
                React.createElement(TextComponent, { children: "\uC774\uBBF8\uC9C0 \uC6A9\uB7C9 : 300Kbyte \uC774\uB0B4\uC5EC\uC57C \uD569\uB2C8\uB2E4." }),
                React.createElement("div", { style: { padding: '10px' } },
                    React.createElement(Upload, { maxCount: 1, fileList: fileList, accept: isPlussharpMobileAppAndAndroid() ? '.jpg,.jpeg,.exe' : 'image/jpg,image/jpeg', customRequest: function (options) { return __awaiter(void 0, void 0, void 0, function () {
                            var onSuccess, onError, file, onProgress, reader;
                            return __generator(this, function (_a) {
                                onSuccess = options.onSuccess, onError = options.onError, file = options.file, onProgress = options.onProgress;
                                if (!file) {
                                    return [2 /*return*/, onError('Error', 'Error')];
                                }
                                if ((file === null || file === void 0 ? void 0 : file.size) > 300000) {
                                    setError('파일 사이즈는 300KB 이하만 가능합니다.');
                                    return [2 /*return*/, onError('Error', 'Error')];
                                }
                                reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = function (e) {
                                    var image = new Image();
                                    //Set the Base64 string return from FileReader as source.
                                    image.src = e.target.result;
                                    // MMS 300kb (가로세로 1000px 이하 권장)
                                    // 친구톡 500kb (가로 500px 이상 / )
                                    // 1. 파일크기 300kb 이하로
                                    // 2. 가로 500px이상 / 세로(가로 기준 0.5~1.3배율 이하)
                                    //Validate the File Height and Width.
                                    image.onload = function () {
                                        var height = this.height;
                                        var width = this.width;
                                        if (width < 500 || width > 1000) {
                                            setError('가로 사이즈는 500px 이상 1000px이하로 전송가능합니다.');
                                            return onError('Error', 'Error');
                                        }
                                        if (height < width * 0.5 || height > width * 1.3) {
                                            setError("\uC138\uB85C \uC0AC\uC774\uC988\uB294 \uAC00\uB85C\uC0AC\uC774\uC988 \uAE30\uC900 0.5~1.3\uBC30\uC728\uB85C \uC804\uC1A1\uC774 \uAC00\uB2A5\uD569\uB2C8\uB2E4.");
                                            return onError('Error', 'Error');
                                        }
                                        return onSuccess('OK!', 'OK!');
                                    };
                                    image.onerror = function () {
                                        var args = [];
                                        for (var _i = 0; _i < arguments.length; _i++) {
                                            args[_i] = arguments[_i];
                                        }
                                        setError('오류가 발생했습니다. 다시 시도해주세요.');
                                        return onError('Error', 'Error');
                                    };
                                };
                                reader.onerror = function (e) {
                                    setFileList([]);
                                    setError('오류가 발생했습니다. 다시 시도해주세요.');
                                    return onError('Error', 'Error');
                                };
                                return [2 /*return*/];
                            });
                        }); }, onChange: function (data) {
                            var _a, _b;
                            if ((_a = data.file) === null || _a === void 0 ? void 0 : _a.error) {
                                return setFileList([]);
                            }
                            setChanged(true);
                            setError(undefined);
                            setFileList((_b = data.fileList) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return !item.error; }).map(function (item) {
                                var ext = item.name.split('.').pop();
                                return item;
                            }));
                        } },
                        React.createElement(ButtonWithPlusCircle, { children: "\uD30C\uC77C \uC120\uD0DD" })),
                    error && (React.createElement(TextComponent, { marginTop: 5, marginBottom: 5, color: COLORS.calendarRedActive, children: error })))) })));
};
export var MessageImage = function (props) {
    var _a = React.useState(), image = _a[0], setImage = _a[1];
    React.useEffect(function () {
        if (!props.image) {
            setImage(undefined);
        }
        if (typeof props.image === 'string') {
            setImage(props.image);
        }
        else {
            if (props.image && props.image[0]) {
                getBase64FromImageFile(props.image[0].originFileObj, function (imageUrl) { return setImage(imageUrl); });
            }
            else {
                setImage(undefined);
            }
        }
    }, [props.image]);
    return (React.createElement(MessageImageDiv, { children: React.createElement(React.Fragment, null,
            React.createElement("img", { src: image, draggable: false }),
            props.onClick && (React.createElement("div", { className: "close", onClick: props.onClick },
                React.createElement(Icon, { name: "close", color: COLORS.white })))) }));
};
export var MessageError = function (props) {
    return props.error ? (React.createElement(Row, { wrap: false, align: "middle" },
        React.createElement(Icon, { name: "alert-triangle", color: COLORS.calendarRedActive }),
        React.createElement(TextComponent, { type: "caption1", color: COLORS.calendarRedActive, children: props.error, marginLeft: 5 }))) : (React.createElement("div", null));
};
var MessageImageDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  width: 80px;\n  height: 80px;\n  display: flex;\n  align-items: center;\n  border-radius: 2px;\n  background-color: var(--gray-7);\n\n  & > img {\n    width: 100%;\n    height: auto;\n    color: white;\n  }\n\n  & .close {\n    border-radius: 2px;\n    top: 5px;\n    right: 5px;\n    position: absolute;\n    cursor: pointer;\n    width: 20px;\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: black;\n  }\n"], ["\n  position: relative;\n  width: 80px;\n  height: 80px;\n  display: flex;\n  align-items: center;\n  border-radius: 2px;\n  background-color: var(--gray-7);\n\n  & > img {\n    width: 100%;\n    height: auto;\n    color: white;\n  }\n\n  & .close {\n    border-radius: 2px;\n    top: 5px;\n    right: 5px;\n    position: absolute;\n    cursor: pointer;\n    width: 20px;\n    height: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: black;\n  }\n"])));
var VariablesSelector = function (props) {
    var variables = props.variables;
    var items = variables.map(function (item) { return ({ name: item, label: item, onClick: function () { return props.onClick(item); } }); });
    return (React.createElement(CustomDropdown, { items: items },
        React.createElement("div", null,
            React.createElement(TextButtonWithIcon, { label: "\uBCC0\uC218\uC785\uB825", icon: React.createElement("img", { src: IMAGES.icon_variable }) }))));
};
var TemplateSaver = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = React.useState(false), visible = _f[0], setVisible = _f[1];
    var createMutation = MessageTemplateQuery.useCreateMessageTemplateMutation();
    var handleSubmit = function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var imageObject, uploadResult, prevImage, prevImageIsString, fileName, fileName, image_1, error_3;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 8, , 9]);
                    imageObject = Lodash.get(props.values, 'content.image.0');
                    uploadResult = void 0;
                    prevImage = (_b = (_a = props.values) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.image;
                    prevImageIsString = prevImage && typeof prevImage === 'string';
                    if (!(imageObject && typeof imageObject !== 'string')) return [3 /*break*/, 4];
                    return [4 /*yield*/, uploadFileToMessageImageDirectory(imageObject.originFileObj, createUUID())];
                case 1:
                    uploadResult = _e.sent();
                    if (!prevImageIsString) return [3 /*break*/, 3];
                    fileName = prevImage.split('/').pop();
                    return [4 /*yield*/, deleteFileToMessageImageDirectory(fileName)];
                case 2:
                    _e.sent();
                    _e.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    if (!(!((_c = props.values.content) === null || _c === void 0 ? void 0 : _c.image) && typeof prevImage === 'string')) return [3 /*break*/, 6];
                    fileName = prevImage.split('/').pop();
                    return [4 /*yield*/, deleteFileToMessageImageDirectory(fileName)];
                case 5:
                    _e.sent();
                    prevImage = '';
                    _e.label = 6;
                case 6:
                    image_1 = (uploadResult === null || uploadResult === void 0 ? void 0 : uploadResult.location) ? uploadResult.location : prevImage;
                    return [4 /*yield*/, createMutation.mutateAsync(__assign(__assign({}, props.values), { id: undefined, storeId: (_d = props.store) === null || _d === void 0 ? void 0 : _d.id, title: formData.title, content: __assign(__assign({}, props.values.content), { image: image_1 }) }))];
                case 7:
                    _e.sent();
                    notification.success({ message: "\uBB38\uC790 \uD15C\uD50C\uB9BF \uC0DD\uC131\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4." });
                    setVisible(false);
                    return [3 /*break*/, 9];
                case 8:
                    error_3 = _e.sent();
                    notification.error({ message: error_3.message });
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        form.setFieldsValue(props.values);
    }, [props.values]);
    var image = (_b = (_a = props.values) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.image;
    var body = (_d = (_c = props.values) === null || _c === void 0 ? void 0 : _c.content) === null || _d === void 0 ? void 0 : _d.body;
    var isAd = (_e = props.values) === null || _e === void 0 ? void 0 : _e.isAd;
    var store = props.store;
    var form = Form.useForm()[0];
    return (React.createElement("div", null,
        React.createElement(TextButtonWithIcon, { label: "\uBB38\uC790\uC800\uC7A5", icon: React.createElement(Icon, { name: "save" }), onClick: function () { return setVisible(true); } }),
        React.createElement(BigTitleModal, { centered: true, visible: visible, cancelText: "\uB2EB\uAE30", okText: "\uC800\uC7A5", onCancel: function () { return setVisible(false); }, onOk: form.submit, title: "\uBB38\uC790\uC800\uC7A5", 
            // width={props.isPC ? 800 : props.width}
            children: React.createElement("div", { style: { padding: '10px' } },
                React.createElement(AntdForm, { formProps: {
                        form: form,
                        initialValues: props.values,
                        layout: 'vertical',
                        onFinish: handleSubmit
                    }, onSubmit: handleSubmit, fields: [
                        {
                            type: 'text',
                            itemProps: {
                                name: 'title',
                                label: '제목',
                                rules: [{ type: 'string', required: true }]
                            },
                            inputProps: { placeholder: '제목' }
                        }
                    ] }),
                React.createElement(MessageTemplateContentDisabledWrapper, null,
                    React.createElement("div", { style: {
                            maxHeight: '360px',
                            overflowY: 'scroll',
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px'
                        } },
                        image && React.createElement(MessageImage, { image: image }),
                        isAd && React.createElement(AdPrefixer, { children: AD_PREFIX((store === null || store === void 0 ? void 0 : store.name) || '') }),
                        React.createElement("div", { style: { whiteSpace: 'pre-line' } }, body),
                        isAd && React.createElement(AdPrefixer, { children: AD_SUFFIX(store === null || store === void 0 ? void 0 : store.id) })))) })));
};
var TemplateSelector = function (props) {
    var store = props.store;
    var storeId = store === null || store === void 0 ? void 0 : store.id;
    var _a = React.useState(), modal = _a[0], setModal = _a[1];
    var _b = React.useState(), selectedTemplate = _b[0], setSelectedTemplate = _b[1];
    var _c = React.useState(), parentId = _c[0], setParentId = _c[1];
    var handleClick = function (data, e) {
        setModal(data.key === '0' ? 'mine' : 'provided');
    };
    var templateList = MessageTemplateQuery.useMessageTemplates(storeId, { enabled: !!storeId && !!modal });
    var items = [
        { label: '내 문자 선택', name: 'mine', key: 'mine', value: 'mine', onClick: handleClick },
        { label: '제공 문자 선택', name: 'provided', key: 'provided', value: 'provided', onClick: handleClick }
    ];
    var getList = function () {
        var _a;
        return (((_a = templateList === null || templateList === void 0 ? void 0 : templateList.data) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return (isMine ? item.storeId === storeId : !item.storeId); }).filter(Boolean)) || []);
    };
    var getListParentByParentId = function (parentId) {
        var _a;
        return (_a = templateList === null || templateList === void 0 ? void 0 : templateList.data) === null || _a === void 0 ? void 0 : _a.find(function (item) { return parentId === item.id && !item.storeId; });
    };
    var isMine = modal === 'mine';
    React.useEffect(function () {
        if (modal === 'provided') {
            if (templateList.data && templateList.data[0]) {
                setParentId(templateList.data[0].id);
            }
        }
    }, [templateList.data]);
    var _d = useWindowSize(), isPC = _d.isPC, height = _d.height;
    var bodyStyle = isPC
        ? { height: '800px', overflowY: 'scroll' }
        : { height: "".concat(height - 150, "px"), overflow: 'scroll' };
    var parent = parentId && getListParentByParentId(parentId);
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomDropdown, { items: items },
            React.createElement(TextButtonWithIcon, { label: "\uBB38\uC790\uC120\uD0DD", icon: React.createElement(Icon, { name: "mail" }) })),
        React.createElement(BigTitleModal, { centered: true, open: !!modal, width: 1200, 
            // onCancel={props.onCancel}
            cancelText: "\uB2EB\uAE30", okText: "\uC120\uD0DD\uC644\uB8CC", okButtonProps: {
                disabled: !selectedTemplate
            }, onCancel: function () { return setModal(undefined); }, onOk: function () {
                if (selectedTemplate) {
                    props.onSelect(selectedTemplate);
                }
                setModal(undefined);
                setSelectedTemplate(undefined);
                setParentId(undefined);
            }, title: isMine ? '내 문자 선택' : '제공 문자 선택', 
            // width={props.isPC ? 800 : props.width}
            children: React.createElement("div", { style: __assign({ padding: '10px' }, bodyStyle) }, isMine ? (React.createElement(Row, { gutter: 16 }, getList().map(function (template) {
                return (React.createElement(Col, { key: template.id, md: 24, lg: 8, style: { width: '100%' } },
                    React.createElement(MessageCard, { isSelected: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id) === template.id, template: template, onClick: function (id) {
                            setSelectedTemplate(template.id === (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id) ? undefined : template);
                        } })));
            }))) : (React.createElement("div", null,
                getList().map(function (item) {
                    return (React.createElement(Button, { style: {
                            marginRight: '5px',
                            backgroundColor: parentId === item.id ? COLORS.primary5 : COLORS.white
                        }, children: item.title, key: item.id, onClick: function () { return setParentId(item.id); } }));
                }),
                React.createElement(Row, { gutter: 16, style: { marginTop: '10px' } }, parent &&
                    parent.children &&
                    parent.children.map(function (template) {
                        return (React.createElement(Col, { key: template.id, md: 24, lg: 8, style: { width: '100%' } },
                            React.createElement(MessageCard, { isSelected: (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id) === template.id, template: template, onClick: function (id) {
                                    setSelectedTemplate(template.id === (selectedTemplate === null || selectedTemplate === void 0 ? void 0 : selectedTemplate.id) ? undefined : template);
                                } })));
                    }))))) })));
};
var TextButtonWithIcon = function (props) {
    return (React.createElement(TextButtonWithIconStyle, { onClick: props.onClick },
        typeof props.icon === 'string' ? React.createElement(Icon, { name: props.icon }) : props.icon,
        React.createElement(TextComponent, { children: props.label })));
};
var AdPrefixer = styled(TextComponent)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  // padding: 16px 0px 5px 12px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  color: ", ";\n"], ["\n  // padding: 16px 0px 5px 12px;\n  padding-top: 5px;\n  padding-bottom: 5px;\n  color: ", ";\n"])), COLORS.calendarRedActive);
var TextButtonWithIconStyle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  margin-right: 10px;\n  & > svg {\n    margin-right: 5px;\n  }\n  & > img {\n    margin-right: 5px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  margin-right: 10px;\n  & > svg {\n    margin-right: 5px;\n  }\n  & > img {\n    margin-right: 5px;\n  }\n"])));
// const Trigger = (props: {name, })
var UnderlineClickableText = styled(TextComponent).attrs({ color: COLORS.calendarRedActive })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-decoration: underline;\n  cursor: pointer;\n"], ["\n  text-decoration: underline;\n  cursor: pointer;\n"])));
var ClickableRow = styled(Row)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var MessageTemplateContentWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: solid 1px ", ";\n  background-color: var(--white);\n  height: 400px;\n  border-radius: 4px;\n  display: flex;\n  flex-direction: column;\n\n  & > .header {\n    border-bottom: solid 1px var(--gray-6);\n    padding: 14px;\n  }\n  & > .footer {\n    border-top: solid 1px var(--gray-6);\n    padding: 14px;\n  }\n\n  & .ProseMirror {\n    height: 100%;\n  }\n"], ["\n  border: solid 1px ", ";\n  background-color: var(--white);\n  height: 400px;\n  border-radius: 4px;\n  display: flex;\n  flex-direction: column;\n\n  & > .header {\n    border-bottom: solid 1px var(--gray-6);\n    padding: 14px;\n  }\n  & > .footer {\n    border-top: solid 1px var(--gray-6);\n    padding: 14px;\n  }\n\n  & .ProseMirror {\n    height: 100%;\n  }\n"])), function (props) { return (props.error ? COLORS.calendarRedActive : COLORS.gray6); });
var MessageTemplateContentDisabledWrapper = styled(MessageTemplateContentWrapper)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border: solid 1px var(--gray-6);\n  background-color: var(--gray-7);\n  height: 360px;\n"], ["\n  border: solid 1px var(--gray-6);\n  background-color: var(--gray-7);\n  height: 360px;\n"])));
var MessageCard = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var hasAction = ((_a = props.template) === null || _a === void 0 ? void 0 : _a.storeId) && (props.handleUpdate || props.handleDelete);
    var image = (_c = (_b = props.template) === null || _b === void 0 ? void 0 : _b.content) === null || _c === void 0 ? void 0 : _c.image;
    var ad = (_d = props.template) === null || _d === void 0 ? void 0 : _d.isAd;
    var hasImageOrAd = image || ad;
    return (React.createElement(MessageCardWrapper, { isSelected: props.isSelected, onClick: function () { var _a; return props.onClick && props.onClick((_a = props.template) === null || _a === void 0 ? void 0 : _a.id); }, cursor: props.onClick ? 'pointer' : 'unset' },
        React.createElement(Row, { className: "header", align: "middle", justify: "space-between", wrap: false },
            React.createElement(Col, { span: 23 },
                React.createElement(TextComponent, { children: (_e = props.template) === null || _e === void 0 ? void 0 : _e.title, ellipsis: true })),
            hasAction && (React.createElement(Col, { span: 1 },
                React.createElement(CustomDropdown, { items: [
                        {
                            name: 'edit',
                            onClick: function () { var _a; return props.handleUpdate((_a = props.template) === null || _a === void 0 ? void 0 : _a.id); },
                            icon: 'edit3',
                            label: '수정'
                        },
                        {
                            name: 'delete',
                            onClick: function () { var _a; return props.handleDelete((_a = props.template) === null || _a === void 0 ? void 0 : _a.id); },
                            icon: 'trash',
                            label: '삭제'
                        }
                    ] },
                    React.createElement(Icon, { name: "more-vertical" }))))),
        React.createElement("div", { className: "content" },
            hasImageOrAd && React.createElement(TextComponent, { children: "".concat(image ? '[사진]' : '', " ").concat(!!ad ? '[광고]' : '') }), (_g = (_f = props.template) === null || _f === void 0 ? void 0 : _f.content) === null || _g === void 0 ? void 0 :
            _g.body),
        props.isSelected && React.createElement(Button, { size: "small", type: "primary", children: "\uC120\uD0DD" })));
};
var MessageCardWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 240px;\n  padding: 0 0 10px;\n  border-radius: 4px;\n  cursor: ", ";\n  border: ", ";\n  width: 100%;\n  margin-bottom: 10px;\n  position: relative;\n\n  & > .header {\n    height: 30px;\n    border-radius: 4px 4px 0 0;\n    border-bottom: solid 1px var(--gray-5);\n    background-color: var(--gray-7);\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n  & > .content {\n    height: 210px;\n    overflow-y: scroll;\n    padding: 10px;\n    overflow-x: hidden;\n    white-space: pre-line;\n  }\n  & svg {\n    cursor: pointer;\n  }\n\n  & > button {\n    position: absolute;\n    bottom: 5px;\n    right: 15px;\n  }\n"], ["\n  height: 240px;\n  padding: 0 0 10px;\n  border-radius: 4px;\n  cursor: ", ";\n  border: ", ";\n  width: 100%;\n  margin-bottom: 10px;\n  position: relative;\n\n  & > .header {\n    height: 30px;\n    border-radius: 4px 4px 0 0;\n    border-bottom: solid 1px var(--gray-5);\n    background-color: var(--gray-7);\n    padding-left: 10px;\n    padding-right: 10px;\n  }\n  & > .content {\n    height: 210px;\n    overflow-y: scroll;\n    padding: 10px;\n    overflow-x: hidden;\n    white-space: pre-line;\n  }\n  & svg {\n    cursor: pointer;\n  }\n\n  & > button {\n    position: absolute;\n    bottom: 5px;\n    right: 15px;\n  }\n"])), function (props) { return props.cursor; }, function (props) { return (props.isSelected ? 'solid 1px var(--primary-1)' : 'solid 1px var(--gray-5)'); });
export var ByteChecker = function (props) {
    var type = { SMS: 90, LMS: 2000, MMS: 2000 };
    var _a = React.useState(''), text = _a[0], setText = _a[1];
    React.useEffect(function () {
        var text = props.isAd ? getAdText(props.store) + props.body : props.body;
        setText(text);
    }, [props.body, props.isAd]);
    var getType = function (byte) {
        return props.hasImage ? 'MMS' : byte < type.SMS ? 'SMS' : 'LMS';
    };
    var byte = getByteLength(text);
    var length = (text || '').length;
    return (React.createElement("div", null,
        (!props.type || props.type === 'message') && (React.createElement(TextComponent, { children: "".concat(byte, " / ").concat(type[getType(byte)], "bytes(").concat(getType(byte), ")") })),
        (!props.type || ['fts', 'fms'].includes(props.type)) && (React.createElement(TextComponent, { children: "".concat(length, " / ").concat(props.hasImage ? 400 : 1000, "\uC790(\uCE74\uCE74\uC624\uD1A1)") }))));
};
export function getAdText(store) {
    var adSuffix = AD_SUFFIX(store === null || store === void 0 ? void 0 : store.id);
    var adPreffix = AD_PREFIX(store === null || store === void 0 ? void 0 : store.name);
    return adSuffix + adPreffix;
}
export default MessageTemplates;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
