var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { AntButton, BodyTemplate, DrawerComponent, HookFormInputs, TextComponent } from 'renderer/components';
import { DATE_FORMAT } from 'renderer/constants';
import { XScrollableTable } from '../../components/antd/Table';
import { AdminQuery } from 'renderer/queries';
import { KRWFomatToString } from 'renderer/components/Intl';
import { DATE_FORMAT_INCLUDE_TIME } from '../../constants/index';
import dayjs from 'dayjs';
import { Button } from 'common-ui';
import Row from 'antd/lib/row';
import DatePicker from 'antd/lib/date-picker';
import { getRanges, useDebounce } from 'renderer/utils';
import { useForm } from 'react-hook-form';
import modal from 'antd/lib/modal';
import Input from 'antd/lib/input/Input';
import Col from 'antd/lib/col';
import { round } from 'lodash';
import { notification } from 'antd/lib';
var RangePicker = DatePicker.RangePicker;
export var InvoiceManagement = function (props) {
    return React.createElement(BodyTemplate, { title: "\uCCAD\uAD6C\uC11C \uAD00\uB9AC", content: React.createElement(SalesTable, null) });
};
var SalesTable = function (props) {
    var _a = React.useState([dayjs().startOf('month'), dayjs().endOf('month')]), values = _a[0], setValues = _a[1];
    var _b = React.useState(), keyword = _b[0], setKeyword = _b[1];
    var debouncedKeyword = useDebounce(keyword, 500);
    var _c = React.useState(false), open = _c[0], setOpen = _c[1];
    var _d = AdminQuery.useOurInvoices({
        page: 1,
        limit: 50,
        startedAt: values[0].toDate(),
        endedAt: values[1].toDate(),
        keyword: debouncedKeyword
    }, { enabled: (values === null || values === void 0 ? void 0 : values.length) === 2 }), pagerResult = _d.pagerResult, isLoading = _d.isLoading, getPaginateObject = _d.getPaginateObject, setNewPagerOptions = _d.setNewPagerOptions;
    var download = AdminQuery.useDownloadOurInvoiceData();
    var _e = React.useState(), invoice = _e[0], setInvoice = _e[1];
    var COLUMNS = [
        {
            id: 'createdAt',
            title: '결제일',
            width: 140,
            render: function (_, originalRow) {
                return dayjs(originalRow.createdAt).format(DATE_FORMAT_INCLUDE_TIME);
            }
        },
        {
            id: 'store.name',
            title: '상점명',
            render: function (_, originalRow) {
                var _a, _b;
                return "(".concat((_a = originalRow === null || originalRow === void 0 ? void 0 : originalRow.store) === null || _a === void 0 ? void 0 : _a.id, ")").concat(((_b = originalRow === null || originalRow === void 0 ? void 0 : originalRow.store) === null || _b === void 0 ? void 0 : _b.name) || '');
            },
            width: 150
        },
        {
            id: 'status',
            title: '결제상태',
            render: function (_, originalRow) {
                return originalRow.typeToString;
            },
            width: 100
        },
        {
            id: '결제내역',
            title: '결제내역',
            width: 200,
            ellipsis: false,
            render: function (_, originalRow) {
                return originalRow.title;
            }
        },
        {
            id: 'finalPrice',
            title: '결제금액',
            width: 100,
            render: function (_, originalRow) {
                return KRWFomatToString({ value: originalRow.finalPrice });
            }
        },
        {
            id: '결제카드',
            title: '결제카드',
            width: 170,
            render: function (_, originalRow) {
                var _a, _b;
                return "".concat((_a = originalRow.iamportData) === null || _a === void 0 ? void 0 : _a.card_name, " ").concat((_b = originalRow.iamportData) === null || _b === void 0 ? void 0 : _b.card_number);
            }
        },
        {
            id: '할부',
            title: '할부',
            width: 50,
            render: function (_, originalRow) {
                var _a, _b;
                return "".concat(((_a = originalRow.iamportData) === null || _a === void 0 ? void 0 : _a.card_quota) === 0 ? '일시불' : "".concat((_b = originalRow.iamportData) === null || _b === void 0 ? void 0 : _b.card_quota, "\uAC1C\uC6D4"));
            }
        },
        {
            id: 'store.createdAt',
            title: '가입일',
            render: function (_, originalRow) {
                var _a;
                return dayjs((_a = originalRow === null || originalRow === void 0 ? void 0 : originalRow.store) === null || _a === void 0 ? void 0 : _a.createdAt).format(DATE_FORMAT);
            },
            width: 100
        },
        {
            id: 'store.referral',
            title: '가입경로',
            render: function (_, originalRow) {
                return ['referral', 'referralAdditional']
                    .map(function (key) { var _a; return (((_a = originalRow === null || originalRow === void 0 ? void 0 : originalRow.store) === null || _a === void 0 ? void 0 : _a.meta) || {})[key] || ''; })
                    .filter(Boolean)
                    .join(' / ');
            },
            width: 170
        },
        {
            id: 'meta.cancelReason',
            title: '취소사유',
            ellipsis: false,
            render: function (_, originalRow) {
                var _a;
                return (_a = originalRow === null || originalRow === void 0 ? void 0 : originalRow.meta) === null || _a === void 0 ? void 0 : _a.cancelReason;
            },
            width: 150
        },
        {
            id: 'cancel',
            title: '취소',
            // width: 50,
            render: function (_, originalRow) {
                var _a, _b;
                return ((_a = originalRow === null || originalRow === void 0 ? void 0 : originalRow.iamportData) === null || _a === void 0 ? void 0 : _a.pg_tid) && originalRow.refundableAmount ? (React.createElement(Button.ContainButton, { disabled: !((_b = originalRow === null || originalRow === void 0 ? void 0 : originalRow.iamportData) === null || _b === void 0 ? void 0 : _b.pg_tid), onClick: function () {
                        setInvoice(originalRow);
                    } }, "\uCDE8\uC18C")) : (React.createElement("div", null));
            }
        }
    ];
    React.useEffect(function () {
        setNewPagerOptions({
            limit: pagerResult.limit,
            startedAt: values[0].startOf('day').toDate(),
            endedAt: values[1].endOf('day').toDate(),
            page: 1,
            keyword: debouncedKeyword
        });
    }, [debouncedKeyword]);
    return (React.createElement("div", { style: { width: '100%', margin: '10px' } },
        React.createElement(Row, { gutter: 8, style: { marginBottom: '8px', marginRight: '20px' } },
            React.createElement(Col, { span: 8 },
                React.createElement(Input, { onChange: function (e) { return setKeyword(e.target.value || ''); } })),
            React.createElement(Col, { flex: 1 },
                React.createElement(Row, { gutter: 8, align: "middle" },
                    React.createElement(Col, null,
                        React.createElement(RangePicker, { presets: getRanges(dayjs()), value: values, showTime: true, format: "YYYY/MM/DD", onChange: function (values) {
                                setValues([values[0].startOf('day'), values[1].endOf('day')]);
                                setNewPagerOptions({
                                    page: 1,
                                    limit: 50,
                                    startedAt: values[0].startOf('day').toDate(),
                                    endedAt: values[1].endOf('day').toDate(),
                                    keyword: debouncedKeyword
                                });
                            } })),
                    React.createElement(Col, null,
                        React.createElement(Button.ContainButton, { children: "EXPORT", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var result;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, download.mutateAsync({
                                                startedAt: values[0].toDate(),
                                                endedAt: values[1].toDate()
                                            })];
                                        case 1:
                                            result = _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); } })),
                    React.createElement(Col, { flex: 1 },
                        React.createElement(Row, { justify: "end" },
                            React.createElement(Button.ContainButton, { children: "\uC218\uB3D9\uACB0\uC81C", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        setOpen(true);
                                        return [2 /*return*/];
                                    });
                                }); } })))))),
        React.createElement(Row, { style: { marginBottom: '8px', marginRight: '20px' }, justify: "end" }),
        React.createElement(XScrollableTable, { style: { width: '100%' }, scroll: { y: 'calc(100vh - 270px)' }, loading: isLoading, columns: COLUMNS, pagination: getPaginateObject(), dataSource: pagerResult.items }),
        invoice && React.createElement(InvoiceCancelForm, { invoice: invoice, onClose: function () { return setInvoice(undefined); } }),
        open && React.createElement(ManualCreateInvoiceForm, { open: open, onClose: function () { return setOpen(false); } })));
};
var ManualCreateInvoiceForm = function (props) {
    var _a, _b;
    var form = useForm();
    React.useEffect(function () {
        form.reset({});
    }, [props.open]);
    var finalPrice = form.watch('finalPrice');
    var vat = round(finalPrice / 11);
    var totalPrice = finalPrice - vat;
    var handleSubmt = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            modal.confirm({
                centered: true,
                content: "".concat(KRWFomatToString({ value: (values === null || values === void 0 ? void 0 : values.finalPrice) || 0 }), "\uB97C \uACB0\uC81C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?"),
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var result, err_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, AdminQuery.createOurInvoice({
                                        storeName: values.storeName,
                                        storeId: values.storeId,
                                        totalVat: vat,
                                        totalPrice: totalPrice,
                                        description: values.description,
                                        cardQuota: values.cardQuota || null
                                    })];
                            case 1:
                                result = _a.sent();
                                notification.info({ message: '결제 성공' });
                                props.onClose();
                                return [3 /*break*/, 3];
                            case 2:
                                err_1 = _a.sent();
                                notification.error({ message: err_1.message });
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); }
            });
            return [2 /*return*/];
        });
    }); });
    return (React.createElement(DrawerComponent, { width: 500, title: "\uACB0\uC81C\uCDE8\uC18C", children: React.createElement("div", null,
            React.createElement(HookFormInputs.NumberInput, { inputProps: {}, label: "\uC0C1\uC810\uC544\uC774\uB514", controlProps: {
                    control: form.control,
                    name: 'storeId',
                    rules: {
                        required: '상점아이디를 입력해주세요.'
                    }
                } }),
            React.createElement(HookFormInputs.Text, { inputProps: {
                    suffix: '원'
                }, label: "\uC0C1\uC810\uBA85", controlProps: {
                    control: form.control,
                    name: 'storeName',
                    rules: {
                        required: '상점명을 입력해주세요.'
                    }
                } }),
            React.createElement(HookFormInputs.NumberInput, { inputProps: {
                    suffix: '원'
                }, label: "\uACB0\uC81C\uAE08\uC561", controlProps: {
                    control: form.control,
                    name: 'finalPrice',
                    rules: {
                        required: '결제금액을 입력해주세요.',
                        min: {
                            value: 0,
                            message: "0\uC6D0 \uC774\uC0C1\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694."
                        }
                    }
                } }),
            React.createElement(TextComponent, { children: "\uACF5\uAE09\uAC00\uC561 : ".concat((_a = (totalPrice || 0)) === null || _a === void 0 ? void 0 : _a.toLocaleString()) }),
            React.createElement(TextComponent, { children: "VAT : ".concat((_b = (vat || 0)) === null || _b === void 0 ? void 0 : _b.toLocaleString()) }),
            React.createElement(HookFormInputs.Textarea, { label: "\uACB0\uC81C\uB0B4\uC5ED", inputProps: {}, controlProps: {
                    rules: {
                        required: '결제내역을 입력해주세요.'
                    },
                    name: "description",
                    control: form.control
                } }),
            React.createElement(HookFormInputs.NumberInput, { inputProps: {}, label: "\uD560\uBD80\uAC1C\uC6D4\uC218", controlProps: {
                    control: form.control,
                    name: 'cardQuota',
                    rules: {
                        max: {
                            value: 12,
                            message: "12\uAC1C\uC6D4 \uB118\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4."
                        },
                        min: {
                            value: 1,
                            message: "1\uAC1C\uC6D4 \uC774\uC0C1\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694."
                        }
                    }
                } }),
            React.createElement(AntButton, { type: "primary", style: { marginTop: '10px', width: '100%' }, children: "\uC218\uB3D9\uACB0\uC81C", onClick: handleSubmt })), open: !!props.open, onClose: function () { return props.onClose(); } }));
};
var InvoiceCancelForm = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var form = useForm();
    React.useEffect(function () {
        var _a;
        form.reset({
            amount: ((_a = props.invoice) === null || _a === void 0 ? void 0 : _a.refundableAmount) || 0,
            reason: "\uCDE8\uC18C\uC0AC\uC720:\n\uCDE8\uC18C\uC790:"
        });
    }, [(_a = props.invoice) === null || _a === void 0 ? void 0 : _a.id]);
    var handleSubmt = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            modal.confirm({
                centered: true,
                content: "".concat(KRWFomatToString({ value: form.getValues('amount') }), "\uC744 \uCDE8\uC18C\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?"),
                onOk: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, AdminQuery.cancelOurInvoice(__assign({ id: props.invoice.id }, form.getValues()))];
                            case 1:
                                result = _a.sent();
                                console.log('result', result);
                                props.onClose();
                                return [2 /*return*/];
                        }
                    });
                }); }
            });
            return [2 /*return*/];
        });
    }); });
    return (React.createElement(DrawerComponent, { width: 500, title: "\uACB0\uC81C\uCDE8\uC18C", children: React.createElement("div", null,
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "\uC0C1\uC810\uBA85 : " }),
                React.createElement(TextComponent, { children: (_c = (_b = props.invoice) === null || _b === void 0 ? void 0 : _b.store) === null || _c === void 0 ? void 0 : _c.name })),
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "\uACB0\uC81C\uB0B4\uC5ED : " }),
                React.createElement(TextComponent, { children: ((_d = props.invoice) === null || _d === void 0 ? void 0 : _d.title) || ((_e = props.invoice) === null || _e === void 0 ? void 0 : _e.description) })),
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "\uACB0\uC81C\uC77C\uC2DC : " }),
                React.createElement(TextComponent, { children: dayjs((_f = props.invoice) === null || _f === void 0 ? void 0 : _f.createdAt).format('YYYY-MM-DD HH:mm:ss') })),
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "\uACB0\uC81C\uAE08\uC561 : " }),
                React.createElement(TextComponent, { children: KRWFomatToString({ value: (_g = props.invoice) === null || _g === void 0 ? void 0 : _g.price }) })),
            React.createElement(Row, null,
                React.createElement(TextComponent, { children: "\uD658\uBD88\uAC00\uB2A5\uAE08\uC561 : " }),
                React.createElement(TextComponent, { children: KRWFomatToString({ value: (_h = props.invoice) === null || _h === void 0 ? void 0 : _h.refundableAmount }) })),
            React.createElement(HookFormInputs.NumberInput, { inputProps: {
                    suffix: '원'
                }, label: "\uCDE8\uC18C\uAE08\uC561", controlProps: {
                    control: form.control,
                    name: 'amount',
                    rules: {
                        required: '취소할 금액을 입력해주세요.',
                        max: {
                            value: (_j = props.invoice) === null || _j === void 0 ? void 0 : _j.refundableAmount,
                            message: "".concat((_k = props.invoice) === null || _k === void 0 ? void 0 : _k.refundableAmount, "\uC744 \uB118\uC744 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.")
                        },
                        min: {
                            value: 0,
                            message: "0\uC6D0 \uC774\uC0C1\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694."
                        }
                    }
                } }),
            React.createElement(HookFormInputs.Textarea, { label: "\uCDE8\uC18C\uC0AC\uC720", inputProps: {}, controlProps: {
                    rules: {
                        required: '취소사유를 입력해주세요.'
                    },
                    name: "reason",
                    control: form.control
                } }),
            React.createElement(AntButton, { type: "primary", style: { marginTop: '10px', width: '100%' }, children: "\uACB0\uC81C\uCDE8\uC18C", onClick: handleSubmt })), open: !!props.invoice, onClose: function () { return props.onClose(); } }));
};
