var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { FullWidthButton, BodyTemplate, DrawerComponent, FullFlex, HookFormInputs, TableComponent } from 'renderer/components';
import { AlimtalkTemplateModel } from 'renderer/models';
import { AdminQuery } from 'renderer/queries';
import * as dayjs from 'dayjs';
import { DATE_FORMAT_INCLUDE_TIME, REGEX_URL } from 'renderer/constants';
import { Button, Layout, Typhography } from 'common-ui';
import { useForm } from 'react-hook-form';
import { notification } from 'antd';
import { useDebounce } from 'renderer/utils';
import { ClickableIcon } from 'common-ui/Icon';
import { Card } from 'antd/lib';
export var AlimtalkManagement = function (props) {
    return React.createElement(BodyTemplate, { title: "\uC54C\uB9BC\uD1A1 \uAD00\uB9AC", content: React.createElement(SalesTable, null) });
};
var SalesTable = function () {
    var _a = AdminQuery.useAlimtalkTemplates({
        page: 1,
        limit: 10
    }), pagerResult = _a.pagerResult, isLoading = _a.isLoading, getPaginateObject = _a.getPaginateObject;
    var columns = [
        {
            id: 'isGroup',
            title: '그룹템플릿',
            render: function (_, originalRow) {
                return "".concat((originalRow === null || originalRow === void 0 ? void 0 : originalRow.isGroupTemplate) ? '그룹' : '개별');
            },
            width: 80
        },
        {
            id: 'store.name',
            title: '상점명',
            render: function (_, originalRow) {
                var _a;
                return "".concat(((_a = originalRow === null || originalRow === void 0 ? void 0 : originalRow.store) === null || _a === void 0 ? void 0 : _a.name) || '');
            },
            width: 150
        },
        {
            id: 'name',
            title: '템플릿명',
            render: function (_, originalRow) {
                return "".concat((originalRow === null || originalRow === void 0 ? void 0 : originalRow.name) || '');
            },
            width: 100
        },
        {
            id: 'name',
            title: '템플릿코드',
            render: function (_, originalRow) {
                return "".concat((originalRow === null || originalRow === void 0 ? void 0 : originalRow.templateCode) || '');
            },
            width: 100
        },
        {
            id: 'name',
            title: '카테고리',
            render: function (_, originalRow) {
                var _a;
                return "".concat(((_a = originalRow === null || originalRow === void 0 ? void 0 : originalRow.data) === null || _a === void 0 ? void 0 : _a.category) || '');
            },
            width: 100
        },
        {
            id: 'status',
            title: '승인상태',
            render: function (_, originalRow) {
                return "".concat((originalRow === null || originalRow === void 0 ? void 0 : originalRow.status) || '');
            },
            width: 100
        },
        {
            id: 'finalPrice',
            title: '플러스친구아이디',
            width: 100,
            render: function (_, originalRow) {
                var _a;
                return (_a = originalRow === null || originalRow === void 0 ? void 0 : originalRow.senderProfile) === null || _a === void 0 ? void 0 : _a.searchName;
            }
        },
        {
            id: '내용',
            title: '',
            width: 500,
            render: function (_, originalRow) {
                var _a;
                return (_a = originalRow.data) === null || _a === void 0 ? void 0 : _a.content;
            }
        },
        {
            id: 'createdAt',
            title: '생성일',
            width: 200,
            render: function (_, originalRow) {
                return dayjs(originalRow.createdAt).format(DATE_FORMAT_INCLUDE_TIME);
            }
        }
    ];
    var _b = React.useState(), template = _b[0], setTemplate = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout.FluidInline, { justify: "end", gutter: 16, style: { padding: '10px' } },
            React.createElement(Button.ContainButton, { onClick: function () { return window.open('https://biz.nitsoft.co.kr/'); } }, "NIT \uAD00\uB9AC\uC790"),
            React.createElement(Button.ContainButton, { onClick: function () { return setTemplate({}); } }, "\uD15C\uD50C\uB9BF \uB4F1\uB85D")),
        React.createElement(TableComponent, { loading: isLoading, columns: columns, pagination: getPaginateObject(), dataSource: pagerResult.items, onRow: function (record) {
                return {
                    onClick: function () {
                        if (!record.isGroupTemplate) {
                            setTemplate(record);
                        }
                    }
                };
            } }),
        template && React.createElement(RegisterAlimtalkTemplate, { data: template, onClose: function () { return setTemplate(null); } })));
};
var RegisterAlimtalkTemplate = function (props) {
    var _a, _b, _c, _d;
    var isUpdated = !!((_a = props.data) === null || _a === void 0 ? void 0 : _a.id);
    var form = useForm({
        defaultValues: {
            autoMessageType: 1,
            templateCode: '',
            storeId: (_b = props.data) === null || _b === void 0 ? void 0 : _b.storeId
        }
    });
    var storeId = form.watch('storeId');
    var autoMessageType = form.watch('autoMessageType');
    var content = form.watch('data.content');
    var debouncedStoreId = useDebounce(storeId, 500);
    var createTemplate = AdminQuery.useCreateCustomerMutation();
    var storeQuery = AdminQuery.useStoreByStoreId(debouncedStoreId, {
        enabled: !!debouncedStoreId,
        retry: 0,
        cacheTime: 60000,
        staleTime: 60000,
        onError: function () {
            notification.error({ message: '상점을 찾을 수 없습니다.' });
            form.reset({
                autoMessageType: 1,
                templateCode: '',
                storeId: Number(debouncedStoreId)
            });
        }
    });
    var templates = AdminQuery.useAlimtalkTemplatesByStoreId(debouncedStoreId, {
        cacheTime: 60000,
        staleTime: 60000,
        enabled: !!debouncedStoreId,
        retry: 0
    });
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var autoMessageType, storeName, rest, _a, categoryName, data, template, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    autoMessageType = values.autoMessageType, storeName = values.storeName, rest = __rest(values, ["autoMessageType", "storeName"]);
                    _a = rest.data, categoryName = _a.categoryName, data = __rest(_a, ["categoryName"]);
                    template = __assign(__assign({}, rest), { data: (values === null || values === void 0 ? void 0 : values.btns)
                            ? __assign(__assign({}, data), { btns: values === null || values === void 0 ? void 0 : values.btns.map(function (btn) { return (__assign(__assign({}, btn), { type: 'WL' })); }) }) : values === null || values === void 0 ? void 0 : values.data, isGroupTemplate: false, provider: 'nit' });
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createTemplate.mutateAsync(template)];
                case 2:
                    _b.sent();
                    notification.info({ message: '템플릿 생성 성공' });
                    props.onClose();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    notification.error({ message: error_1.message });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    React.useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (!props.data) {
            storeQuery.remove();
            form.reset({});
            return;
        }
        var store = storeQuery === null || storeQuery === void 0 ? void 0 : storeQuery.data;
        if (store) {
            var length = (_a = templates.data) === null || _a === void 0 ? void 0 : _a.filter(function (template) {
                var autoMessageKey = Number(template.templateCode.split('_')[0].replace('AT', ''));
                return autoMessageKey === autoMessageType;
            }).length;
            var type = AlimtalkTemplateModel.ALIMTALK_TEMPLATE_TYPE.find(function (item) { return item.keyIndex === autoMessageType; });
            console.log('isUpdated', isUpdated);
            form.reset(isUpdated
                ? __assign(__assign({}, props.data), { storeName: store.name }) : {
                storeId: store.id,
                name: "".concat(type.keyName).concat(length + 2),
                templateCode: "AT".concat(autoMessageType, "_").concat(length + 1, "_").concat(store.id),
                data: {
                    content: content,
                    securityFlag: false,
                    category: type.category,
                    categoryName: type.categoryName,
                    assignType: '기본형'
                },
                senderProfile: {
                    profileKey: (_d = (_c = (_b = store.smsService) === null || _b === void 0 ? void 0 : _b.kakao) === null || _c === void 0 ? void 0 : _c.nit) === null || _d === void 0 ? void 0 : _d.profileKey,
                    searchName: (_g = (_f = (_e = store.smsService) === null || _e === void 0 ? void 0 : _e.kakao) === null || _f === void 0 ? void 0 : _f.nit) === null || _g === void 0 ? void 0 : _g.searchName,
                    profileName: (_k = (_j = (_h = store.smsService) === null || _h === void 0 ? void 0 : _h.kakao) === null || _j === void 0 ? void 0 : _j.nit) === null || _k === void 0 ? void 0 : _k.profileName
                },
                storeName: store.name,
                autoMessageType: autoMessageType
            });
        }
    }, [(_c = storeQuery === null || storeQuery === void 0 ? void 0 : storeQuery.data) === null || _c === void 0 ? void 0 : _c.id, autoMessageType, props.data]);
    var btnsValue = form.watch('data.btns');
    var enableAddBtn = !isUpdated && ((btnsValue === null || btnsValue === void 0 ? void 0 : btnsValue.length) || 0) < 6;
    return (React.createElement(DrawerComponent, { open: !!props.data, title: "\uC54C\uB9BC\uD1A1 \uD15C\uD50C\uB9BF \uB4F1\uB85D", onClose: props.onClose, children: React.createElement("div", null,
            React.createElement(HookFormInputs.NumberInput, { label: '상점아이디', controlProps: {
                    name: 'storeId',
                    control: form.control,
                    disabled: isUpdated,
                    rules: {
                        required: true
                    }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.SelectInput, { label: "\uC790\uB3D9\uBB38\uC790 \uD56D\uBAA9", controlProps: {
                    name: "autoMessageType",
                    control: form.control,
                    rules: {
                        required: true
                    }
                }, inputProps: {
                    style: { width: '320px' },
                    disabled: isUpdated,
                    options: AlimtalkTemplateModel.ALIMTALK_TEMPLATE_TYPE.map(function (type) {
                        return {
                            id: type.keyName,
                            value: type.keyIndex,
                            label: type.keyName
                        };
                    })
                } }),
            React.createElement(HookFormInputs.Textarea, { label: '내용', controlProps: {
                    name: 'data.content',
                    control: form.control,
                    rules: {
                        required: true
                    }
                }, inputProps: {
                    style: { height: '400px' }
                } }),
            React.createElement(HookFormInputs.Text, { label: '상점명', controlProps: {
                    name: 'storeName',
                    control: form.control,
                    rules: {
                        required: true
                    }
                }, inputProps: {
                    disabled: isUpdated
                } }),
            React.createElement(HookFormInputs.Text, { label: '검색용아이디', controlProps: {
                    name: 'senderProfile.searchName',
                    control: form.control,
                    rules: {
                        required: true
                    }
                }, inputProps: {
                    disabled: true
                } }),
            React.createElement(HookFormInputs.Text, { label: '프로필키', controlProps: {
                    name: 'senderProfile.profileKey',
                    control: form.control,
                    rules: {
                        required: true
                    }
                }, inputProps: {
                    disabled: true
                } }),
            React.createElement(HookFormInputs.Text, { label: '템플릿 이름', controlProps: {
                    name: 'name',
                    control: form.control,
                    rules: {
                        required: true
                    }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: '템플릿코드', controlProps: {
                    name: 'templateCode',
                    control: form.control,
                    rules: {
                        required: true
                    }
                }, inputProps: {} }),
            React.createElement(HookFormInputs.Text, { label: '카테고리', controlProps: {
                    name: 'data.categoryName',
                    control: form.control,
                    rules: {
                        required: true
                    }
                }, inputProps: {
                    disabled: true
                } }),
            React.createElement(FullFlex, { "$fullWidth": true, vertical: true, gap: 8, style: { marginTop: '10px' } }, (_d = form.watch('data.btns')) === null || _d === void 0 ? void 0 :
                _d.map(function (btn, index) {
                    return (React.createElement(Card, { title: React.createElement(HookFormInputs.Text, { controlProps: {
                                name: "data.btns.".concat(index, ".name"),
                                control: form.control,
                                rules: {
                                    required: true
                                }
                            }, inputProps: {} }), extra: !isUpdated && (React.createElement(ClickableIcon, { name: "x", size: 24, onClick: function () {
                                form.setValue('data.btns', form.watch('data.btns').filter(function (_, i) { return i !== index; }));
                            } })), style: { width: '100%' } },
                        React.createElement(HookFormInputs.Text, { label: '모바일 링크', controlProps: {
                                name: "data.btns.".concat(index, ".url_mobile"),
                                control: form.control,
                                rules: {
                                    required: true,
                                    pattern: REGEX_URL
                                }
                            }, inputProps: {} }),
                        React.createElement(HookFormInputs.Text, { label: 'PC 링크', controlProps: {
                                name: "data.btns.".concat(index, "..url_pc"),
                                control: form.control,
                                rules: {
                                    required: true,
                                    pattern: REGEX_URL
                                }
                            }, inputProps: {} })));
                }),
                React.createElement(FullWidthButton, { disabled: !enableAddBtn, children: "\uBC84\uD2BC\uCD94\uAC00", onClick: function () {
                        form.setValue('data.btns', __spreadArray(__spreadArray([], (btnsValue || []), true), [
                            {
                                name: '',
                                type: 'WL',
                                url_mobile: '',
                                url_pc: ''
                            }
                        ], false));
                    } })),
            React.createElement(Typhography.TextComponent, null, "\uAC15\uC870\uCC98\uB9AC: \uD574\uC81C, \uBA54\uC2DC\uC9C0 \uC720\uD615: \uAE30\uBCF8\uD615, \uD15C\uD50C\uB9BF \uBCF4\uC548: \uC0AC\uC6A9\uD574\uC81C"),
            React.createElement(Button.ContainButton, { style: { marginTop: '10px', width: '100%' }, onClick: form.handleSubmit(onSubmit) }, "\uC800\uC7A5")) }));
};
